import React from 'react';
import {images} from "../../constants";

const HeaderPage = () => {

    return (
            <header className="header">
                <nav className="navbar">
                    <div className="logo"><a href=""><img src={images.logo} alt="logo"/></a></div>
                    <div className="phone"> T: +250 789 429 057</div>
                    <div className="main-menu">
                        <ul>
                            <li><a href="#services">Our services</a></li>
                            <li><a href="#gallery">Gallery</a></li>
                            <li><a href="#about">About us</a></li>
                            <a href="#contact"><span className="book-us">Book us</span></a>
                        </ul>
                    </div>

                    <div className="hamburger-menu" id="hamburger-menu">
                        <div className="burger">
                            <svg id="burger-svg" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                <title>Show / Hide Navigation</title>
                                <rect className="burger-svg__base" width="50" height="50"/>
                                <g className="burger-svg__bars">
                                    <rect className="burger-svg__bar burger-svg__bar-1" x="14" y="18" width="22" height="2"/>
                                    <rect className="burger-svg__bar burger-svg__bar-2" x="14" y="24" width="22" height="2"/>
                                    <rect className="burger-svg__bar burger-svg__bar-3" x="14" y="30" width="22" height="2"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </nav>
                <div className="page-title">
                    <div className="container">
                        <h2>Agensy</h2>
                        <h4>Agensy is compatible with popular WordPress plugins</h4>
                    </div>
                </div>
            </header>
    );
};
export default HeaderPage;