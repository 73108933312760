import React from 'react';
import './css/index'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";

const App = () => {
    return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/"><Home /></Route>
                    <Route path="/about"><About /></Route>
                    <Route path={NotFound}/>
                </Switch>
            </BrowserRouter>
    );
};

export default App;
