import React, {useRef, useState} from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {

    const [formData, setFormData] = useState({ username: '', subject: '', phoneNumber: '', email: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { username, email, message, phoneNumber, subject } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const form = useRef();

    const sendEmail = (e) => {
        setLoading(true);
        e.preventDefault();

        emailjs.sendForm('service_00iy7ou', 'template_g6uk99a', form.current, 'user_NMVZ37RFNdsU15Wtvxjnc')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
        e.target.reset();
        setLoading(false);
        setIsFormSubmitted(true);
    };

    return (
            <>
                <section className="contact" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 wow fadeInUp">
                                <h5>ADDRESS</h5>
                                <address>Yan Events<br/>
                                    KG20 Kigali
                                </address>
                            </div>
                            <div className="col-md-6 wow fadeInUp">
                                <h5>CALL US</h5>
                                <address>T: +250 789 429 057</address>
                            </div>
                            <div className="col-12 wow fadeInUp">
                                <h5>REACH US</h5>
                                <address>
                                    <p>We allways looking for talented peoples, soo let's work together</p>
                                    <a href="mailto:yanevents2@gmail.com">yanevents2@gmail.com</a>
                                </address>
                            </div>
                            <div className="col-lg-7 wow fadeInUp">
                                <div className="contact-form">
                                    <h5>CONTACT FORM</h5>

                                    {!isFormSubmitted ? (
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div className="form-group">
                                            <input type="text" name="username" value={username} autoComplete="off" required={true} onChange={handleChangeInput} placeholder="Your name"/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="email" autoComplete="off" value={email} required={true} onChange={handleChangeInput} placeholder="Your e-mail"/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="phoneNumber" value={phoneNumber} autoComplete="off" required={true} onChange={handleChangeInput} placeholder="Your phone number"/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="subject" autoComplete="off" value={subject} required={true} onChange={handleChangeInput} placeholder="Subject"/>
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" autoComplete="off" required={true} value={message} onChange={handleChangeInput} placeholder="Message"/>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" name="submit">
                                                <strong>{!loading ? 'Send' : 'Sending...'}<b/> <i/></strong>
                                            </button>
                                        </div>
                                    </form>
                                    ) : (
                                            <div className="form-group">
                                                <div id="success" className="alert alert-success wow fadeInUp"
                                                     role="alert"> Your message was sent successfully! We will be in touch as
                                                    soon as we can.
                                                </div>
                                            </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
    );
};

export default Contact;
