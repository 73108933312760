import React, {useEffect, useState} from 'react';
import {images} from "../../constants";
import {client, urlFor} from "../../client";

const Header = () => {

    const [skills, setSkills] = useState([]);

    useEffect(() => {
        const query = '*[_type == "skills"]';
        client.fetch(query)
                .then((data) => {
                    setSkills(data);
                })
    }, []);


    return (
            <header className="header">
                <nav className="navbar">
                    <div className="logo"><a href=""><img src={images.logo} alt="logo"/></a></div>
                    <div className="phone"> T: +250 789 429 057</div>
                    <div className="main-menu">
                        <ul>
                            <li><a href="#services">Our services</a></li>
                            <li><a href="#gallery">Gallery</a></li>
                            <li><a href="#about">About us</a></li>
                            <a href="#contact"><span className="book-us">Book us</span></a>
                        </ul>
                    </div>

                    <div className="hamburger-menu" id="hamburger-menu">
                        <div className="burger">
                            <svg id="burger-svg" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                <title>Show / Hide Navigation</title>
                                <rect className="burger-svg__base" width="50" height="50"/>
                                <g className="burger-svg__bars">
                                    <rect className="burger-svg__bar burger-svg__bar-1" x="14" y="18" width="22" height="2"/>
                                    <rect className="burger-svg__bar burger-svg__bar-2" x="14" y="24" width="22" height="2"/>
                                    <rect className="burger-svg__bar burger-svg__bar-3" x="14" y="30" width="22" height="2"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </nav>
                <div className="headlines">
                    <div className="container accueil">
                        <figure className="project-image reveal-effect masker wow">
                            <img src={images.dessin} alt="dessin" className="dessin"/>
                        </figure>
                        <div className="droite">
                            <h1>
                                <span>Kigali</span> based <br/>
                                Event <span>Agency</span>
                            </h1>
                            <div>
                                <ul className="skills">
                                    {skills.map((skill, index) => (
                                    <li key={skill.title + index}>
                                        <img src={urlFor(skill.imgUrl)} alt={skill.title}/>
                                        {skill.title}
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
    );
};
export default Header;